<template>
  <v-theme-provider :dark="dark">
    <div>
      <template v-for="({ icon, text, title: t }, i) in this[countury]">
        <base-avatar-card-two
          :key="i"
          :icon="icon"
          dark
          :outlined="false"
          :title="!dense ? t : undefined"
          color="transparent"
          horizontal
          space="0"
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <div
            v-html="text"
            style="color:white;"
          />
        </base-avatar-card-two>
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContactNanotech',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
      countury: String,
    },

    data: () => ({
      'Goh Ah Chye': [
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '+60 12-329-8289',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'nanotech6393@hotmail.com',
        },
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'No 20, Lorong CP8/23, Cheras Perdana, Cheras, 43200, Selangor, Malaysia',
        },
      ],
    }),
  }
</script>
