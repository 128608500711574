import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import VideoBackground from 'vue-responsive-video-background-player'
import '@/firebase/firebase'

Vue.component('video-background', VideoBackground)
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
