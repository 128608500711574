<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'NanotechSolutions Sdn Bhd',
      titleTemplate: 'NanotechSolutions Homepage',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keyword', content: 'Nanotech, NanotechSolutions, NanotechSolutions Sdn Bhd, Chemical, Chemical Distributor, chemical supplier, Malaysia, Specialty Chemical, Chemical Industries, Rubber Industries, Raw Material, Petrochemical, Coating, Paper, Personal care, Food, Activated carbon, coconut shell, oleochemical, antioxidant, antitack powder free, antifoam, acrylic polymer binder, biocides, coagulant, dispersing agent, fragrance, flavour, formic acid, nitrile butadiente latex, NBL, synthetic latex supplier, latex, acrylonitrile butadiene latex, PVC, PVC Resin paste, Polyurethane Wet Donning, Polyurethane polymer, Titanium dioxide, Wetting agents, Wetting donning agents, South East Asia, formulations, chemical logistics, international distribution, chemical solution provider, connected globally, industry standard chemical products, quality raw materials' },
        { name: 'description', content: 'Nanotech Solutions is based in Malaysia specialising in specialty chemicals for a diverse number of industries including rubber, glove, caoting, paper, food, personal care and many others.' },
      ],
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
